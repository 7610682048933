@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;300;400;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");
@font-face {
    font-family: "Hexagon";
    font-weight: 400;
    src: url("./fonts/HEXAGON_.TTF") format("truetype");
}

/* icons */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.loader div:first-child {
    animation-delay: 0.1s;
}

.loader div:nth-child(2) {
    animation-delay: 0.3s;
}

.loader div:nth-child(3) {
    animation-delay: 0.6s;
}
